@media only screen and (prefers-color-scheme: dark) {
  code[class*="language-"], pre[class*="language-"] {
    color: #ccc;
    text-align: start;
    white-space: pre;
    word-spacing: normal;
    word-break: normal;
    word-wrap: normal;
    tab-size: 4;
    -webkit-hyphens: none;
    hyphens: none;
    background: none;
    font-family: Consolas, Monaco, Andale Mono, Ubuntu Mono, monospace;
    font-size: small;
    line-height: 1.5;
  }

  pre.prismjs, code[class*="language-"] {
    background: #2d2d2d;
  }

  :not(pre) > code[class*="language-"] {
    border-radius: var(--border-radius);
    white-space: normal;
  }

  .token.comment, .token.block-comment, .token.prolog, .token.doctype, .token.cdata {
    color: #999;
  }

  .token.punctuation {
    color: #ccc;
  }

  .token.tag, .token.attr-name, .token.namespace, .token.deleted {
    color: #e2777a;
  }

  .token.function-name {
    color: #6196cc;
  }

  .token.boolean, .token.number, .token.function {
    color: #f08d49;
  }

  .token.property, .token.class-name, .token.constant, .token.symbol {
    color: #f8c555;
  }

  .token.selector, .token.important, .token.atrule, .token.keyword, .token.builtin {
    color: #cc99cd;
  }

  .token.string, .token.char, .token.attr-value, .token.regex, .token.variable {
    color: #7ec699;
  }

  .token.operator, .token.entity, .token.url {
    color: #67cdcc;
  }

  .token.important, .token.bold {
    font-weight: bold;
  }

  .token.italic {
    font-style: italic;
  }

  .token.entity {
    cursor: help;
  }

  .token.inserted {
    color: green;
  }
}

@media only screen and (prefers-color-scheme: light) {
  code[class*="language-"], pre[class*="language-"] {
    color: #657b83;
    text-align: start;
    white-space: pre;
    word-spacing: normal;
    word-break: normal;
    word-wrap: normal;
    tab-size: 4;
    -webkit-hyphens: none;
    hyphens: none;
    font-family: Consolas, Monaco, Andale Mono, Ubuntu Mono, monospace;
    font-size: small;
    line-height: 1.5;
  }

  pre.prismjs, code[class*="language-"] {
    background-color: #fdf6e3;
  }

  :not(pre) > code[class*="language-"] {
    border-radius: var(--border-radius);
  }

  .token.comment, .token.prolog, .token.doctype, .token.cdata {
    color: #93a1a1;
  }

  .token.punctuation {
    color: #586e75;
  }

  .token.namespace {
    opacity: .7;
  }

  .token.property, .token.tag, .token.boolean, .token.number, .token.constant, .token.symbol, .token.deleted {
    color: #268bd2;
  }

  .token.selector, .token.attr-name, .token.string, .token.char, .token.builtin, .token.url, .token.inserted {
    color: #2aa198;
  }

  .token.entity {
    color: #657b83;
    background: #eee8d5;
  }

  .token.atrule, .token.attr-value, .token.keyword {
    color: #859900;
  }

  .token.function, .token.class-name {
    color: #b58900;
  }

  .token.regex, .token.important, .token.variable {
    color: #cb4b16;
  }

  .token.important, .token.bold {
    font-weight: bold;
  }

  .token.italic {
    font-style: italic;
  }

  .token.entity {
    cursor: help;
  }
}

.code-highlight {
  float: inline-start;
  min-inline-size: 100%;
}

.code-line {
  border-inline-start-width: 4px;
  border-inline-start-color: #1f293700;
  margin-inline: -16px;
  padding-inline: 16px;
  display: block;
}

.code-line.inserted {
  background-color: #10b98133;
}

.code-line.deleted {
  background-color: #ef444433;
}

.highlight-line {
  background-color: #1081b933;
  border-inline-start-width: 4px;
  border-inline-start-color: #3b82f6;
  margin-inline: -16px;
}

.line-number:before {
  text-align: end;
  color: #9ca3af;
  content: attr(line);
  inline-size: 3ch;
  margin-inline-end: 1.5ch;
  display: inline-block;
}

.show-lines {
  counter-reset: code-line;
  margin-block-end: var(--typography-spacing-vertical);
}

.show-lines pre {
  margin-block-end: 0;
}

.show-lines pre > code > span:before {
  text-align: end;
  color: #9ca3af;
  content: counter(code-line);
  counter-increment: code-line;
  inline-size: 3ch;
  margin-inline-end: 1.5ch;
  display: inline-block;
}

/*# sourceMappingURL=app.66ca120e.css.map */
