@media only screen and (prefers-color-scheme: dark) {
  /**
 * prism.js tomorrow night eighties for JavaScript, CoffeeScript, CSS and HTML
 * Based on https://github.com/chriskempson/tomorrow-theme
 * @author Rose Pritchard
 */

  code[class*="language-"],
  pre[class*="language-"] {
    color: #ccc;
    background: none;
    font-family: Consolas, Monaco, "Andale Mono", "Ubuntu Mono", monospace;
    font-size: small;
    text-align: start;
    white-space: pre;
    word-spacing: normal;
    word-break: normal;
    word-wrap: normal;
    line-height: 1.5;
    tab-size: 4;
    hyphens: none;
  }

  /* Code blocks */
  pre.prismjs,
  code[class*="language-"] {
    background: #2d2d2d;
  }

  /* Inline code */
  :not(pre) > code[class*="language-"] {
    border-radius: var(--border-radius);
    white-space: normal;
  }

  .token.comment,
  .token.block-comment,
  .token.prolog,
  .token.doctype,
  .token.cdata {
    color: #999;
  }

  .token.punctuation {
    color: #ccc;
  }

  .token.tag,
  .token.attr-name,
  .token.namespace,
  .token.deleted {
    color: #e2777a;
  }

  .token.function-name {
    color: #6196cc;
  }

  .token.boolean,
  .token.number,
  .token.function {
    color: #f08d49;
  }

  .token.property,
  .token.class-name,
  .token.constant,
  .token.symbol {
    color: #f8c555;
  }

  .token.selector,
  .token.important,
  .token.atrule,
  .token.keyword,
  .token.builtin {
    color: #cc99cd;
  }

  .token.string,
  .token.char,
  .token.attr-value,
  .token.regex,
  .token.variable {
    color: #7ec699;
  }

  .token.operator,
  .token.entity,
  .token.url {
    color: #67cdcc;
  }

  .token.important,
  .token.bold {
    font-weight: bold;
  }

  .token.italic {
    font-style: italic;
  }

  .token.entity {
    cursor: help;
  }

  .token.inserted {
    color: green;
  }
}

@media only screen and (prefers-color-scheme: light) {
  /*
 Solarized Color Schemes originally by Ethan Schoonover
 http://ethanschoonover.com/solarized

 Ported for PrismJS by Hector Matos
 Website: https://krakendev.io
 Twitter Handle: https://twitter.com/allonsykraken)
*/

  /*
SOLARIZED HEX
--------- -------
base03    #002b36
base02    #073642
base01    #586e75
base00    #657b83
base0     #839496
base1     #93a1a1
base2     #eee8d5
base3     #fdf6e3
yellow    #b58900
orange    #cb4b16
red       #dc322f
magenta   #d33682
violet    #6c71c4
blue      #268bd2
cyan      #2aa198
green     #859900
*/

  code[class*="language-"],
  pre[class*="language-"] {
    color: #657b83; /* base00 */
    font-family: Consolas, Monaco, "Andale Mono", "Ubuntu Mono", monospace;
    font-size: small;
    text-align: start;
    white-space: pre;
    word-spacing: normal;
    word-break: normal;
    word-wrap: normal;
    line-height: 1.5;
    tab-size: 4;
    hyphens: none;
  }

  /* Code blocks */
  pre.prismjs,
  code[class*="language-"] {
    background-color: #fdf6e3; /* base3 */
  }

  /* Inline code */
  :not(pre) > code[class*="language-"] {
    border-radius: var(--border-radius);
  }

  .token.comment,
  .token.prolog,
  .token.doctype,
  .token.cdata {
    color: #93a1a1; /* base1 */
  }

  .token.punctuation {
    color: #586e75; /* base01 */
  }

  .token.namespace {
    opacity: 0.7;
  }

  .token.property,
  .token.tag,
  .token.boolean,
  .token.number,
  .token.constant,
  .token.symbol,
  .token.deleted {
    color: #268bd2; /* blue */
  }

  .token.selector,
  .token.attr-name,
  .token.string,
  .token.char,
  .token.builtin,
  .token.url,
  .token.inserted {
    color: #2aa198; /* cyan */
  }

  .token.entity {
    color: #657b83; /* base00 */
    background: #eee8d5; /* base2 */
  }

  .token.atrule,
  .token.attr-value,
  .token.keyword {
    color: #859900; /* green */
  }

  .token.function,
  .token.class-name {
    color: #b58900; /* yellow */
  }

  .token.regex,
  .token.important,
  .token.variable {
    color: #cb4b16; /* orange */
  }

  .token.important,
  .token.bold {
    font-weight: bold;
  }

  .token.italic {
    font-style: italic;
  }

  .token.entity {
    cursor: help;
  }
}

/**
 * Inspired by gatsby remark prism - https://www.gatsbyjs.com/plugins/gatsby-remark-prismjs/
 * 1. Make the element just wide enough to fit its content.
 * 2. Always fill the visible space in .code-highlight.
 */
.code-highlight {
  float: inline-start; /* 1 */
  min-inline-size: 100%; /* 2 */
}

.code-line {
  display: block;
  padding-inline: 16px;
  margin-inline: -16px;
  border-inline-start-width: 4px;
  border-inline-start-color: rgb(31 41 55 / 0%); /* Set code block color */
}

.code-line.inserted {
  background-color: rgb(16 185 129 / 20%); /* Set inserted line (+) color */
}

.code-line.deleted {
  background-color: rgb(239 68 68 / 20%); /* Set deleted line (-) color */
}

.highlight-line {
  margin-inline: -16px;
  background-color: rgb(16 129 185 / 20%); /* Set highlight bg color */
  border-inline-start-width: 4px;
  border-inline-start-color: rgb(
    59 130 246
  ); /* Set highlight accent border color */
}

.line-number::before {
  display: inline-block;
  inline-size: 3ch;
  text-align: end;
  margin-inline-end: 1.5ch;
  color: rgb(156 163 175); /* Line number color */
  content: attr(line);
}

.show-lines {
  counter-reset: code-line;
  margin-block-end: var(--typography-spacing-vertical);
}

.show-lines pre {
  margin-block-end: 0;
}

.show-lines pre > code > span::before {
  display: inline-block;
  inline-size: 3ch;
  text-align: end;
  margin-inline-end: 1.5ch;
  color: rgb(156 163 175); /* Line number color */
  content: counter(code-line);
  counter-increment: code-line;
}
